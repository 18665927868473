import { db } from '@/firebase'
import { collection, doc, query, where } from 'firebase/firestore'
import { Adjustment, Category, Merchant, Product, Stock, Terminal, Variant } from 'nube-stocktake-sdk'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useCollection, useDocument } from 'vuefire'

export const useGlobalStore = defineStore(`global`, () => {
    const currentTerminal = ref<Terminal>()
    async function bindCurrentTerminal(id: string): Promise<void> {
        const docRef = doc(collection(db, `terminals`), id)
        const { promise } = useDocument<Terminal>(docRef, { target: currentTerminal })
        await promise.value
    }

    const currentMerchant = ref<Merchant>()
    async function bindCurrentMerchant(id: string): Promise<void> {
        const docRef = doc(collection(db, `merchants`), id)
        const { promise } = useDocument<Merchant>(docRef, { target: currentMerchant })
        await promise.value
    }

    const currentAdjustment = ref<Adjustment>()
    async function bindCurrentAdjustment(id: string): Promise<void> {
        const docRef = doc(collection(db, `adjustments`), id)
        const { promise } = useDocument<Adjustment>(docRef, { target: currentAdjustment })
        await promise.value
    }

    const categories = ref<Category[]>()
    async function bindCategories(merchantId: string): Promise<void> {
        const queryRef = query(collection(db, `categories`), where(`merchantId`, `==`, merchantId), where(`status`, `==`, `ACTIVE`))
        const { promise } = useCollection<Category>(queryRef, { target: categories })
        await promise.value
    }

    const products = ref<Product[]>()
    async function bindProducts(merchantId: string): Promise<void> {
        const queryRef = query(collection(db, `products`), where(`merchantId`, `==`, merchantId), where(`status`, `==`, `ACTIVE`))
        const { promise } = useCollection<Product>(queryRef, { target: products })
        await promise.value
    }

    const variants = ref<Variant[]>()
    async function bindVariants(merchantId: string): Promise<void> {
        const queryRef = query(collection(db, `variants`), where(`merchantId`, `==`, merchantId), where(`status`, `==`, `ACTIVE`))
        const { promise } = useCollection<Variant>(queryRef, { target: variants })
        await promise.value
    }

    const stocks = ref<Stock[]>()
    async function bindStocks(shopId: string): Promise<void> {
        const queryRef = query(collection(db, `stocks`), where(`shop.id`, `==`, shopId))
        const { promise } = useCollection<Stock>(queryRef, { target: stocks })
        await promise.value
    }

    return {
        currentTerminal,
        bindCurrentTerminal,
        currentMerchant,
        bindCurrentMerchant,
        currentAdjustment,
        bindCurrentAdjustment,
        categories,
        bindCategories,
        products,
        bindProducts,
        variants,
        bindVariants,
        stocks,
        bindStocks,
    }
})
