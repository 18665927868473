import { useGlobalStore } from '@/stores/global'
import { Device } from '@capacitor/device'
import { alertController } from '@ionic/vue'
import { createRouter, createWebHistory } from '@ionic/vue-router'
import { listAdjustments, openAdjustment } from 'nube-stocktake-sdk'
import { storeToRefs } from 'pinia'
import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
    {
        path: `/`,
        component: () => import(`@/layouts/TabsLayout.vue`),
        children: [
            {
                path: `menu`,
                component: () => import(`@/views/MenuPage.vue`),
            },
            {
                path: `category/:id`,
                component: () => import(`@/views/CategoryPage.vue`),
            },
            {
                path: `adjustment`,
                component: () => import(`@/views/AdjustmentPage.vue`),
            },
            {
                path: `stock`,
                component: () => import(`@/views/StockPage.vue`),
            },
            {
                path: `setting`,
                component: () => import(`@/views/SettingPage.vue`),
            },
        ],
    },
    {
        path: `/activate`,
        component: () => import(`@/views/ActivatePage.vue`),
    },
    {
        path: `/product/:id`,
        component: () => import(`@/views/ProductPage.vue`),
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

router.beforeEach(async (to, from, next) => {
    try {
        const alert = await alertController.create({
            header: `載入中...`,
            backdropDismiss: false,
        })
        await alert.present()

        const uuid = await setUUID()

        const globalStore = useGlobalStore()

        const { currentTerminal, currentMerchant, currentAdjustment, categories, products, variants, stocks } = storeToRefs(globalStore)

        if (currentTerminal.value === undefined) await globalStore.bindCurrentTerminal(uuid)
        if (!currentTerminal.value || currentTerminal.value.status !== `ACTIVE`) {
            alert.dismiss()
            if (to.path !== `/activate`) return next({ path: `/activate` })
            return next()
        }

        const bindings = [
            !currentMerchant.value && globalStore.bindCurrentMerchant(currentTerminal.value.merchant.id),
            !categories.value && globalStore.bindCategories(currentTerminal.value.merchant.id),
            !products.value && globalStore.bindProducts(currentTerminal.value.merchant.id),
            !variants.value && globalStore.bindVariants(currentTerminal.value.merchant.id),
            !stocks.value && globalStore.bindStocks(currentTerminal.value.shop.id),
        ]
        await Promise.all(bindings)

        if (currentAdjustment.value) {
            alert.dismiss()
            if (to.path === '/' && currentAdjustment.value.status === `OPEN`) return next({ path: '/menu' })
            if (to.path === '/') return next({ path: '/adjustment' })
            if (to.path === '/activate') return next({ path: '/menu' })
            return next()
        }

        const filteredAdjustments = await listAdjustments({
            merchantId: currentTerminal.value.merchant.id,
            shopId: currentTerminal.value.shop.id,
            terminalId: currentTerminal.value.id,
        })
        if (filteredAdjustments.length === 0) {
            const adjustment = await openAdjustment({
                merchantId: currentTerminal.value.merchant.id,
                shopId: currentTerminal.value.shop.id,
                terminalId: currentTerminal.value.id,
            })
            await globalStore.bindCurrentAdjustment(adjustment.id)
        } else {
            await globalStore.bindCurrentAdjustment(filteredAdjustments[0].id)
        }

        alert.dismiss()
        if (to.path === '/' && currentAdjustment.value!.status === `OPEN`) return next({ path: '/menu' })
        if (to.path === '/') return next({ path: '/adjustment' })
        if (to.path === '/activate') return next({ path: '/menu' })
        return next()
    } catch (e: any) {
        console.error(e)
        return next({ path: '/activate' })
    }
})

async function setUUID(): Promise<string> {
    let uuid = localStorage.getItem(`uuid`)
    if (uuid) return uuid

    const deviceId = await Device.getId()
    const uuidRegex = /.{4}(?!$)/g
    uuid = deviceId.uuid.slice(-12).toUpperCase().replace(uuidRegex, `$&-`)
    localStorage.setItem(`uuid`, uuid)
    return uuid
}

export default router
