import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
    apiKey: 'AIzaSyBev6CMlJc3V1KEEaOPaGs6kxqK3AUENZA',
    authDomain: 'nube-stocktake-dev.firebaseapp.com',
    projectId: 'nube-stocktake-dev',
    storageBucket: 'nube-stocktake-dev.appspot.com',
    messagingSenderId: '647415507430',
    appId: '1:647415507430:web:d3722c35d2c5bc7fda4e27',
}

export const firebaseApp = initializeApp(firebaseConfig)
export const db = getFirestore(firebaseApp)
