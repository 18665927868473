import { sendApi } from './base.api';
export async function openAdjustment(request) {
    return await sendApi(`/adjustments/open`, request);
}
export async function closeAdjustment(id) {
    return await sendApi(`/adjustments/${id}/close`);
}
export async function cancelAdjustment(id) {
    return await sendApi(`/adjustments/${id}/cancel`);
}
export async function listAdjustments(request = {}) {
    return await sendApi(`/adjustments/list`, request);
}
export async function getAdjustment(id) {
    return await sendApi(`/adjustments/${id}/get`);
}
export async function createAdjustmentProduct(id, request) {
    return await sendApi(`/adjustments/${id}/products/create`, request);
}
export async function updateAdjustmentProduct(id, index, request) {
    return await sendApi(`/adjustments/${id}/products/${index}/update`, request);
}
export async function deleteAdjustmentProduct(id, index) {
    await sendApi(`/adjustments/${id}/products/${index}/delete`);
    return true;
}
