import axios, { AxiosError } from 'axios';
export async function sendApi(uri, data) {
    try {
        const response = await axios({ url: process.env.VUE_APP_BASE_URL + uri, method: 'post', data });
        return response.data;
    }
    catch (error) {
        if (error instanceof AxiosError)
            throw { message: error.response?.data.message };
        if (error instanceof Error)
            throw { message: error.message };
        console.error(error);
        throw { message: error };
    }
}
